// match bootstrap sizes, also add xxl
const sizes = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
  xxxl: '1920px',
  contentMax: '1440px',
}

const bootstrapGutterWidth = '12px'
const bootstrapGutterWidthx2 = '24px'

const fontSizeBase = 1

const font = {
  family: {
    body: `Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    bold: 'Arial, sans-serif',
    black: 'Arial, sans-serif',
    extraBlack: 'Arial, sans-serif',
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  size: {
    xs: `${fontSizeBase * 0.75}rem`, // 12px
    sm: `${fontSizeBase * 0.875}rem`, // 14px
    base: `${fontSizeBase}rem`, // 16px
    md: `${fontSizeBase * 1.5}rem`, // 24px
    lg: `${fontSizeBase * 1.75}rem`, // 28px
    xl: `${fontSizeBase * 2.125}rem`, // 34px
    xxl: `${fontSizeBase * 2.5}rem`, // 40px
    xxxl: `${fontSizeBase * 4.625}rem`, // 74px
  },
  lineHeight: {
    sm: 1.1,
    base: 1.4,
    headings: 1,
  },
  h1: {
    size: `${fontSizeBase * 3.25}rem`,
  },
  h2: {
    size: `${fontSizeBase * 2.75}rem`,
  },
  h3: {
    size: `${fontSizeBase * 2.25}rem`,
  },
  h4: {
    size: `${fontSizeBase * 1.75}rem`,
  },
  h5: {
    size: `${fontSizeBase * 1.5}rem`,
  },
  h6: {
    size: `${fontSizeBase * 1.15}rem`,
  },
}

const ThemeExport = {
  sizes,
  bootstrapGutterWidth,
  bootstrapGutterWidthx2,
  font,
  transitionBase: 'all .4s ease-in-out',
  colors: {
    primary: '#00B2A9',
    secondary: '#FFC72C',
    tertiary: '#333F48',
    quaternary: '#B5DDD8',
    white: '#ffffff',
    whiteOff: '#f2f2f2',
    black: '#020202',
    blackOff: '#333333',
    grey: '#707070',
    grey1: '#555555',
    grey2: '#878787',
    grey3: '#C4C4C4',
    grey4: '#f6f6f6',
    grey5: '#E5E5E5',
    blue: '#626C80',
    midBlue: '#586BB8',
    red: '#BB133E',
    scarlet: '#D8431B',
    navy: '#000089',
    pink: '#F43581',
    purple: '#702283',
    yellow: '#F2C83D',
    neonGreen: '#B0F794',
    turquoise: '#00ADB9',
    cyan: '#8ADAF1',
    orange: '#DA880F',
    gradient: `linear-gradient(180deg, #333348 0%, #00B2A9 100%)`,
    gradientLeft: `linear-gradient(90deg, #333F48 0%, rgba(51, 63, 72, 0) 73.7%)`,
    gradientUp: `linear-gradient(180deg, rgba(51, 63, 72, 0) 0%, #333F48 100%)`,
    titleGradient: `-webkit-linear-gradient(#000089, #586BB8)`,
  },
}

export default ThemeExport
