import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import 'scss/main.scss'

import GlobalStyles from 'utils/GlobalStyles'
import theme from 'utils/Theme'
import Header from 'components/Header'
import Footer from 'components/Footer'
import SEO from 'utils/Seo'

// https://medium.com/@chrisfitkin/how-to-smooth-scroll-links-in-gatsby-3dc445299558
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Layout = ({ children, title, description }) => {
  const { allSite } = useStaticQuery(graphql`
    query SiteQuery {
      allSite {
        nodes {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    }
  `)
  const icon = 'test.png'
  const settings = allSite.nodes[0].siteMetadata
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: `${settings.title}`,
    description: `${settings.description}`,
    url: `${settings.siteUrl}`,
    logo: `${settings.siteUrl}/${icon}`,
  }
  useEffect(() => {
    const handleFirstTab = e => {
      if (e.keyCode === 9) {
        // the "I am a keyboard user" key
        document.body.classList.add('user-is-tabbing')
        window.removeEventListener('keydown', handleFirstTab)
      }
    }
    window.addEventListener('keydown', handleFirstTab)

    return () => {
      window.removeEventListener('keydown', handleFirstTab)
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO schemaMarkup={schema} title={title} description={description} />
        <GlobalStyles />
        {/* <Header /> */}
        <main>{children}</main>
        {/* <Footer /> */}
      </>
    </ThemeProvider>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
