import React from 'react'
import styled, { css } from 'styled-components'
import FacebookIcon from 'svgs/facebook.svg'
import TwitterIcon from 'svgs/twitter.svg'
import InstaIcon from 'svgs/instagram.svg'
import YouTubeIcon from 'svgs/youtube.svg'
import { media } from 'utils/Media'

const SocialStyles = styled.div`
  ${props =>
    props.$footer &&
    css`
      width: 100%;
      position: static;
      background-color: transparent;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        width: 80%;
        margin: 2rem auto 0 auto;
        a {
          svg {
            transition: var(--transition);
            width: 25px;
            height: 25px;
            fill: var(--cream);
            &:hover {
              fill: var(--red);
            }
          }
        }
      }
    `}
  ${props =>
    props.$fixed &&
    css`
      display: none;
      @media ${media.md} {
        display: block;
        position: fixed;
        background-color: var(--black);
        right: 0rem;
        padding: 1rem;
        width: 50px;
        height: 235px;
        top: 40%;
        margin: 0;
        z-index: 5;
        transition: var(--transition);
        &:hover {
          background-color: var(--main);
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        div {
          flex-direction: column;
          a {
            svg {
              width: 23px;
              height: 23px;
              transition: var(--transition);
              &:hover {
                fill: var(--black);
              }
            }
          }
        }
      }
    `}
`
const Socials = props => {
  const i = true
  return (
    <SocialStyles {...props}>
      <div>
        <a
          href="https://www.facebook.com/MousetrapLondon/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Click here</span>
          <FacebookIcon />
        </a>
        <a
          href="https://twitter.com/MousetrapLondon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Click here</span>
          <TwitterIcon />
        </a>
        <a
          href="https://www.instagram.com/themousetraplondon/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Click here</span>
          <InstaIcon />
        </a>
        <a
          href="https://www.youtube.com/channel/UCkFb8o3gXGW-ozlLLqt9SeA/featured"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Click here</span>
          <YouTubeIcon />
        </a>
      </div>
    </SocialStyles>
  )
}

export default Socials
