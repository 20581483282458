import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as common from 'components/Common'
import { media } from 'utils/Media'

const FooterStyles = styled.footer`
  ${props =>
    props.$main &&
    css`
      width: 100%;
      padding: 2rem 0 4rem 0;
      background-color: var(--black);
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: var(--auto);
        padding: 0;
        gap: 0.25rem;
        li {
          a {
            font-size: 0.85rem;
            color: rgba(255, 255, 255, 0.85) !important;
            &:hover,
            &:active {
              color: rgba(255, 255, 255, 0.85) !important;
              text-decoration: underline solid 1px !important;
            }
          }
        }
        .sep {
          font-size: 0.85rem;
          color: rgba(255, 255, 255, 0.85) !important;
        }
      }
      @media ${media.md} {
        p,
        li a {
          font-size: 1rem;
        }
      }
    `}
  ${props =>
    props.$legal &&
    css`
      width: 100%;
      padding: 2rem 0 4rem 0;
      background-color: var(--black);

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: var(--auto);
        padding: 0;
        gap: 0.25rem;
        li {
          a {
            font-size: 0.85rem;
            color: rgba(255, 255, 255, 0.85) !important;
            &:hover,
            &:active {
              color: rgba(255, 255, 255, 0.85) !important;
              text-decoration: underline solid 1px !important;
            }
          }
        }
        .sep {
          font-size: 0.85rem;
          color: rgba(255, 255, 255, 0.85) !important;
        }
      }
      @media ${media.md} {
        p,
        li a {
          font-size: 1rem;
        }
      }
    `}
`
const Footer = props => (
  <FooterStyles {...props}>
    <section>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="sep">|</li>
        <li>
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </li>
        <li className="sep">|</li>
        <li>
          <Link to="/cookie-policy/">Cookie Policy</Link>
        </li>
      </ul>
    </section>
  </FooterStyles>
)

export default Footer
