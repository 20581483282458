import { createGlobalStyle, withTheme } from 'styled-components'
import { media } from 'utils/Media'
import Animations from './Animations'
import Typography from './Typography'
import Vars from './Vars'

const GlobalStyles = createGlobalStyle`
    ${Vars}
    ${Animations}
    ${Typography}
  body {
    font-family: var(--fontMain)!important;
    font-size: ${props => props.theme.font.size.base};
    line-height: ${props => props.theme.font.lineHeight.base};
    color: ${props => props.theme.colors.black};
    background-color: var(--black)!important;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    margin: 0 auto;
    height: auto;
    /* transition: ${props => props.theme.transitionBase}; */
    
    &.nav-active {
      overflow: hidden;
    }
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) div[role="button"]:focus {
    outline: none;
  }
    
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.black};
    font-family: var(--fontMain);
    line-height: ${props => props.theme.font.lineHeight.headings};
    margin-bottom: 1rem;
  }

  h1 {
    font-size: ${props => props.theme.font.h3.size};
    @media ${media.sm} {
      font-size: ${props => props.theme.font.h2.size};
    }
    @media ${media.md} {
      font-size: ${props => props.theme.font.h1.size};
    }
  }

  h2 {
    font-size: ${props => props.theme.font.h4.size};
    @media ${media.sm} {
      font-size: ${props => props.theme.font.h3.size};
    }
    @media ${media.md} {
      font-size: ${props => props.theme.font.h2.size};
    }
  }

  h3 {
    font-size: ${props => props.theme.font.h4.size};
    @media ${media.md} {
      font-size: ${props => props.theme.font.h3.size};
    }
  }

  h4 {
    font-size: ${props => props.theme.font.h4.size};
  }

  h5 {
    font-size: ${props => props.theme.font.h5.size};
  }

  h6 {
    font-size: ${props => props.theme.font.h6.size};
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  a {
    color: inherit!important;
    transition: color .4s ease;

    //!Need to make on brand
    &:hover {
      color: ${props => props.theme.colors.midBlue}; 
      text-decoration: underline solid ${props =>
        props.theme.colors.midBlue} 2px;
    }
  }
  a:-webkit-any-link {
    text-decoration: none;
  }
  strong {
    font-weight: ${props => props.theme.font.weight.bold};
  }

  ul {
    padding-left: 20px;
  }

  img {
    max-width: 100%;
  }
  ::selection {
        background: var(--black); 
        color: var(--main);
    }
  //? For screen readers 
  .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
  }
`

export default withTheme(GlobalStyles)
