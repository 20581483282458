import Footer from 'components/Footer'
import React from 'react'
import styled from 'styled-components'
import Logo from 'svgs/white-logo.svg'
import { AnimatedBackground } from '../AnimatedBackground'

const PlaceholderStyles = styled.section`
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  position: relative;
  z-index: 101;
  section {
    position: relative;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .logo {
      text-align: center;
      margin: 0 auto;
      width: 70%;
      max-width: 320px;
      svg {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 255, 0.4));
      }
    }
    h1 {
      color: var(--white);
      margin-top: 2rem;
      font-size: clamp(1rem, -0.875rem + 8.333vw, 2.5rem);

      font-weight: 800;
      letter-spacing: 1.5px;
    }
  }
`
const Placeholder = () => {
  const i = true
  return (
    <PlaceholderStyles>
      <section>
        <div className="logo">
          <Logo />
          <h1>COMING SOON</h1>
        </div>
      </section>
      <Footer $main />
      <AnimatedBackground />
    </PlaceholderStyles>
  )
}

export default Placeholder
