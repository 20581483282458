import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as THREE from 'three'
// import WAVES from 'vanta/dist/vanta.waves.min.js';
import WAVES from 'utils/helpers/vanta.waves.min.js'

const AnimateHeaderBackgroundStyles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  canvas {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
  }
`
class AnimateHeaderBackground extends React.Component {
  constructor() {
    super()
    this.vantaRef = React.createRef()
  }

  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x0,
    })
  }

  componentWillUnmount() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  }

  render() {
    return <AnimateHeaderBackgroundStyles ref={this.vantaRef} />
  }
}

export default AnimateHeaderBackground
