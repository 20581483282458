import { css } from 'styled-components'

import NunitoSansBoldWOFF from 'utils/fonts/nunitosans-bold-webfont.woff'
import NunitoSansBoldWOFF2 from 'utils/fonts/nunitosans-bold-webfont.woff2'

import NunitoSansRegularWOFF from 'utils/fonts/nunitosans-regular-webfont.woff'
import NunitoSansRegularWOFF2 from 'utils/fonts/nunitosans-regular-webfont.woff2'

const Typography = css`
  @font-face {
    font-family: 'nunitosans';
    src: url(${NunitoSansBoldWOFF}) format('woff2'),
      url(${NunitoSansBoldWOFF2}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'nunitosans';
    src: url(${NunitoSansRegularWOFF}) format('woff2'),
      url(${NunitoSansRegularWOFF2}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Typography
